import React from 'react'
import styled from "styled-components"

const GridContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 40px;

  @media only screen and (min-width: 750px) { 
    grid-template-columns: 1fr 1fr; 
    grid-auto-rows: 1fr;
  }

  @media only screen and (min-width: 1250px) { 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-auto-rows: 1fr;
  }

  @media only screen and (max-width: 750px) { 
    grid-template-columns: 1fr; 
    grid-auto-rows: 1fr;
  }
`

export default GridContainer