import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import theme from "./theme"
import { Link } from "gatsby"

const AboutMeContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;

    background-color: ${theme.accentColor};

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    font-family: ${theme.fontSerif};
    color: ${theme.primaryFontColor};

    a:link, a:visited {
        text-decoration: none;
        color: ${theme.primaryFontColor};
    }
`

const StyledImg = styled(Img)`
    border-radius: 50%;
    margin-left: 30px;
    margin-right: 30px;
`;

const Title = styled.h2`
    font-weight: 30;
    font-size: 1.2em;
`

const Text = styled.p`
    font-weight: 30;
    font-size: 0.8em;
    max-width: 500px;
`

const AboutMe = ({ data }) => (
    <StaticQuery
        query={graphql`
            query {
                file(relativePath: {eq: "images/AboutMe.jpg"}) {
                    absolutePath
                    childImageSharp {
                        fixed(width: 120, height: 120) {
                            ...GatsbyImageSharpFixed
                          }
                    }
                }
            }
        `}
        render={data => (
            <AboutMeContainer>
                <div/>
                    <Link to="/sites/AboutMe">
                        <StyledImg fixed={data.file.childImageSharp.fixed} />
                    </Link>
                <div>
                    <Title>
                        <Link to="/sites/AboutMe">
                        About Me  
                        </Link>
                    </Title>
                    
                    <Text>
                        <Link to="/sites/AboutMe">
                        Hi ich bin Linda. <br/>Ich beschäftige mich  seit ca. 2 Jahren mit  gesunder, zuckerfreier  Low Carb Ernährung.<br/>
                        Seit neustem versuche ich mich vorwiegend vegan zu ernähren und schrecke dabei auch nicht vor Experimenten aller Art zurück ;)
                        </Link>
                    </Text>
                </div>
            </AboutMeContainer>
        )}
    />    
)

export default AboutMe
