import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "./layout/theme"
import DateUtils from "./DateUtils"

import BlogPostPreviewImage from "./BlogPostPreviewImage"
import Badges from "./Badges"

const BlogPostPreviewItemContainer = styled.div`
    padding: 0px;

    box-sizing: border-box;
    min-width: 280px;
`

const Title = styled.h3`
    font-family: ${theme.fontSansSerif};
    font-weight: 400;
    font-size: 1.2em;

    color: ${theme.primaryFontColor};
    text-decoration: none; 
    
    padding-top: 6px;
    margin: 0px;

    a:link, a:visited {
        text-decoration: none;
        color: ${theme.primaryFontColor};
    }
    
    a:hover {
        color: ${theme.primaryClor};
    }
`

const DateLine = styled.p`
    font-family: ${theme.fontSansSerif};
    font-weight: 100;
    font-size: 0.8em;
    color: ${theme.lightFontColor};
    
    padding-top: 8px;
    margin: 0px;
`

const BadgeWrapper = styled.div `
    margin-top: 10px;
    font-size: 0.7em;
`

const BlogPostPreviewItem = ({ item }) => {
    return (
    <BlogPostPreviewItemContainer>
        { item.frontmatter.titleImageName && 
            <Link to={item.fields.slug}>
                <BlogPostPreviewImage imageName={item.frontmatter.titleImageName}/>
            </Link>
        }

        <BadgeWrapper>
            <Badges 
                categoryName={item.frontmatter.category} 
                tagNames={item.frontmatter.tags}/>
        </BadgeWrapper>

        <Title>
            <Link to={item.fields.slug}>
            {item.frontmatter.title}
            </Link>
        </Title>
        
        {/* <DateLine>
            { DateUtils.formatDate(item.frontmatter.date) }
        </DateLine> */}
    </BlogPostPreviewItemContainer>
    )
}

export default BlogPostPreviewItem